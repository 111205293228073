import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { title: "PLIP Report" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_row, { style: {"margin-bottom":"15px"} }, {
        default: _withCtx(() => [
          _createVNode(_component_a_button, {
            type: "primary",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.downloadLatest()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Download Plip Report ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}