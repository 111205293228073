

import { defineComponent } from 'vue'
import { Modal } from 'ant-design-vue'
import {appURL} from "@/axios";

export default defineComponent({
  components: {},
  setup () {
    const downloadLatest = (record: any) => {
      const xhr = new XMLHttpRequest()
      let api_url = process.env.VUE_APP_URL
      let download_url = appURL + 'backend/download/plip_reports/latest'

      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          window.location.href = download_url
          return
        }

        if (xhr.readyState === 4 && xhr.status === 500) {
          Modal.error({
            title: 'Download failed',
            content: 'No PLIP report to be downloaded.',
          })
          return
        }
      }

      xhr.open('head', download_url)
      xhr.send(null)
    }
    return {
      downloadLatest,
    }
  }
})
